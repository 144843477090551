// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");
@import "./mixins.scss";
body {
	font-family: "Poppins", sans-serif;
}

.subtitle {
	font-weight: 300;
}

label,
b,
strong {
	font-weight: 600;
}

.big-title {
	font-size: 46px;
	text-align: center;
	@include for-phone-only {
		font-size: 30px;
	}
}
