@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
.days {
	width: 100%;
	&-label {
		margin-bottom: 20px;
		@include for-phone-only {
			margin-bottom: 5px;
		}
	}
	&-items {
		width: 100%;
		max-width: 100%;
		list-style: none;
		display: flex;
		align-items: center;
		overflow-x: scroll;
		@include for-phone-only {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 15px;
			.day {
				// width: 100% !important;
				min-width: 60px;
			}
		}
		&::-webkit-scrollbar {
			display: none !important;
		}
		.day {
			cursor: pointer;
			border-radius: 7px;
			padding: 0 20px;
			min-width: 60px;
			min-height: 50px;
			margin-right: 8px;
			border: 1px solid $color-gray-dark;
			@include full-center();
			@include for-phone-only {
				margin: 0;
			}
			&-active {
				background-color: $color-primary;
				// border-color: $color-primary;
				p {
					color: $color-background;
				}
			}
			&-text {
				opacity: 0.4;
			}
			&:last-child {
				margin: 0;
			}
		}
		.select {
			@include for-phone-only {
				grid-column: 1 / span 2;
			}
			&-active {
				input {
					border-color: $color-primary !important;
					color: $color-text !important;
				}
			}
			input {
				border: 2px solid $color-gray;
				height: 50px;
				color: $color-gray-dark;
			}
		}
	}
}

.custom-date-button {
	.react-datepicker-wrapper {
		@include for-phone-only {
			width: 100%;
		}
	}

	.react-datepicker {
		border-color: $color-gray;
		box-shadow: $shadow;
		&__triangle {
			// margin-top: -5px !important;
			border-bottom-color: $color-secondary !important;
			&::before {
				border-bottom-color: $color-secondary !important;
			}
		}
		.react-datepicker__day--keyboard-selected {
			background: $color-primary;
		}
		&__header {
			background-color: $color-secondary;
			color: #fff;
		}
		&__current-month,
		&__day-name {
			color: #fff;
		}
		&__day--selected {
			background-color: $color-secondary !important;
		}
		&__month-container {
			@include for-phone-only {
				width: calc(100vw - 40px);
			}
		}
	}

	// .react-datepicker__input-container {
	// 	display: none;
	// }
}
