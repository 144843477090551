@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.login-view {
	background: $color-secondary;
	-webkit-flex: 0 1 100%;
	flex: 1;
	min-height: calc(100vh - #{$nav-height});
	display: flex;
	align-items: center;
	flex-direction: column;

	.card {
		background: $color-secondary;
		width: 500px;
		max-width: 90%;
	}
	&-logo {
		@include full-center();
		padding: 40px 0;
		width: max-content;
		margin: 0 auto;

		img {
			width: 100%;
			max-width: 200px;
		}
		.caption {
			color: #fff;
			text-align: right;
			width: 100%;
			font-weight: 100;
			margin-top: 10px;
		}
	}
	.no-margin {
		margin: 0 !important;
	}
	.input {
		margin-bottom: 20px;
	}
	.forgot {
		justify-content: flex-start !important;
		margin-bottom: 20px;
		text-transform: inherit;
		color: #fff;
		font-weight: 100;
	}
	.button {
		display: flex;
		justify-content: center;
	}
	.styled-text-bold {
		color: #fff;
	}

	.no-account-cta {
		margin: 40px 0;
		@include full-center();
		color: #fff;
		.styled-text {
			color: #fff;
		}
		.button {
			color: #fff;
			text-transform: inherit;
		}
	}

	.button-full {
		margin-top: 20px;
		display: block;
	}

	.button-row {
		display: flex;
		justify-content: space-between;
	}
}
