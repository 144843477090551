@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
@import "react-datepicker/dist/react-datepicker";

.input-gray {
	input,
	textarea {
		background: $color-gray;
	}
}

.input {
	display: flex;
	flex-direction: column;
	&-error {
		input,
		textarea {
			border: 1px solid #d63031 !important;
		}
	}
	&-increments-error {
		.increments {
			border: 1px solid #d63031 !important;
		}
	}
	.MuiInput-root {
		&:hover {
			&::before {
				border: 0px;
			}
		}
		&::before,
		&::after {
			content: "" !important;
			display: none;
			border: 0px solid transparent;
		}
	}

	&-row {
		display: flex;
		input {
			border-radius: 5px 0 0 5px !important;
			flex: 1 1 auto !important;
		}
	}
	label {
		margin-bottom: 5px;
		font-size: 14px;
		color: #433;
		line-height: 18px;
		.optional {
			color: #e6e6e6;
		}
	}
	textarea {
		min-height: 200px;
		font-family: "Poppins", sans-serif !important;
	}
	input {
		min-height: $input-height !important;
	}
	input[type="time"]:invalid {
		border: 1px solid #d63031 !important;
	}
	input,
	textarea,
	.date-picker-input {
		display: block;
		box-sizing: border-box !important;
		padding: 10px;
		border-radius: 5px;
		border: 1px solid $color-gray-dark;
		color: #434343;
		font-size: 14px;
		font-family: "Poppins", sans-serif !important;
		font-weight: 400 !important;

		&:focus {
			outline: none;
		}
		&::placeholder {
			color: $color-gray-dark;
			@include placeholder();
		}
	}
	.date-picker {
		height: 45px;
	}
	.date-picker-input {
		height: 45px;
		min-width: 140px;
		cursor: pointer;
		.placeholder {
			color: $color-gray-dark;
			@include placeholder();
		}
	}
	.rc-time-picker {
		width: 120px;
		&-clear {
			display: flex;
			height: auto !important;
			top: 0;
			bottom: 0;
			align-items: center;
		}
	}
}

.rc-time-picker-panel-inner {
	width: 122px;
}
.rc-time-picker-panel-select {
	width: 60px;
}
.rc-time-picker-panel-input-wrap {
	height: 45px;
	padding: 0;
	border-radius: 5px;
	width: 120px;
	max-width: 120px;
	input {
		border-radius: 5px;
		height: 45px;
		display: block;
		box-sizing: border-box !important;
		padding: 10px;
		color: #434343;
		font-size: 14px;
		font-family: "Poppins", sans-serif !important;
		font-weight: 400 !important;
		// padding: 10px;
	}
}

.input-button {
	display: flex;
	border: 0px;
	border-radius: 0 5px 5px 0;
	align-items: center;
	background-color: $color-primary;
	color: $color-background;
	padding: 0 20px;
	cursor: pointer;
	font-weight: 600;
	&-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&:focus {
		outline: none;
	}
	.icon {
		padding-left: 10px;
		i {
			color: $color-background;
		}
	}
}

.increments {
	display: flex;
	border: 1px solid $color-gray-dark;
	border-radius: 5px;
	&-icon {
		user-select: none;
		height: $input-height;
		width: $input-height;
		cursor: pointer;
		border-radius: 5px;
		color: $color-gray-dark;
		@include full-center();
		&:hover {
			background: #f5f5f5;
		}
	}
	input {
		max-width: 50px;
		border: 0px;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
