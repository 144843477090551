@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";
.contact-view {
	.info {
		@include full-center();
		padding: 0 0 80px 0;
		.button {
			margin-top: 40px;
		}

		p {
			max-width: 700px;
			text-align: center;
			font-size: 20px;
			margin-top: 10px;
		}
	}
	form {
		max-width: 600px;
		display: block;
		margin: 0 auto;
	}
	.button-row {
		display: flex;
		justify-content: space-between;
	}
	.row {
		display: flex;
		align-items: center;
		@include for-phone-only {
			align-items: flex-start;
			flex-direction: column;
			.input {
				width: 100%;
			}
		}
		.styled-text,
		.input {
			flex: 1;
		}
		&-checkbox {
			margin-right: 10px;
		}
	}
	input,
	textarea {
		margin-bottom: 20px;
	}
}
