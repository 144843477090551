.customer-reservation-view {
	h1 {
		color: #fff !important;
	}
	.description {
		display: block;
		margin-bottom: 20px;
	}
	.buttons {
		.button {
			margin-right: 20px;
		}
	}
}
