@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.reservation-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	.timeslot-form {
		padding: 20px 0;
	}
	.full {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.timeslot-form-success {
		flex: 1;
	}
	&-loading,
	&-empty {
		@include full-center();
		flex: 1;
		i {
			margin-bottom: 20px;
		}
		.button {
			margin-top: 20px;
		}
	}
}
