@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.slide-show {
	position: relative;
	overflow: hidden;
	.arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;

		&-next {
			right: -40px;
			i {
				transform: translateX(-15px) !important;
			}
		}
		&-prev {
			left: -40px;
			i {
				transform: translateX(15px) !important;
			}
		}
		.icon {
			cursor: pointer;
			@include circle(80px);
			z-index: 88;
			@include full-center();
			background-color: rgba(#000, 0.6);
			i {
				color: #fff;
				font-size: 24px;
				transform: translateX(10px);
			}
		}
	}
	img {
		width: 100%;
	}
}
