@import "../../../../styles/mixins.scss";
@import "../../../../styles/vars.scss";

.filter-modal {
	max-width: 400px;
	@include for-tablet-under {
		max-width: 90%;
		margin: 0 auto;
	}
	@include for-phone-only {
		max-width: 100%;
	}
	.filter-block {
		&-head {
			border-bottom: 1px solid #f0f0f0;
			padding: 12px 0;
			text-align: center;
			text-transform: uppercase;
			color: #433;
			label {
				font-weight: bold !important;
			}
		}
		&-body {
			padding: 24px 0;
		}
	}
	.input-number {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		width: 100% !important;
		margin-left: 0;
		label {
			margin: 0;
		}
	}
	.buttons-center {
		@include full-center();
		@include for-phone-only {
			z-index: 88;
			position: fixed;
			bottom: 0px;
			left: 0;
			right: 0;
			justify-content: center;
			padding-bottom: 24px;
			background: #484b38;
			&:empty {
				background: transparent;
			}
			.button {
				margin-top: calc(-#{$button-height} / 2) !important;
				background: $color-secondary !important;
				margin-right: 20px;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	.checkbox-wrapper {
		margin-bottom: 6px;
		.checkbox-label {
			font-size: 14px;
			margin-left: 6px;
		}
	}
}
