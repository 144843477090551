@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.tp2-button {
	margin-top: 15px;
}

.tp2-form-is-open {
	@include for-phone-only {
		display: none !important;
	}
}

.tp2 {
	margin-top: 15px;
	display: flex;
	flex: 1;
	@include for-phone-only {
		flex-direction: column;
	}

	&-button {
		max-width: 420px;
		@include full-center();
	}
	.buttons {
		display: flex;
	}
	.card-info {
		@include for-phone-only {
			padding: 20px;
		}
	}

	.MuiFormControl-root {
		@include for-tablet-under {
			width: 100%;
		}
		@include for-phone-only {
			margin-bottom: 10px !important;
		}
	}

	&-loading {
		flex: 1;
		@include for-tablet-under {
			width: 100%;
			padding: 40px 20px;
		}
		@include full-center();
		// .button {
		// 	margin-top: 20px;
		// }
		.icon {
			margin-top: 20px;
			img {
				width: 50px;
			}
		}
	}
	h1 {
		@include for-tablet-under {
			font-size: 16px;
			vertical-align: middle;
			line-height: 16px;
		}
	}
	&-items {
		display: flex;
		flex-direction: column;
		background: $color-gray;
		// padding: 20px;
		overflow-y: auto;
		width: 420px;
		max-height: 600px;
		// @include for-phone-only {
		// 	display: none;
		// }
		@include for-tablet-under {
			width: 100%;
		}
	}
	&-body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.type-row {
		align-items: center;
		@include for-phone-only {
			align-items: flex-start;
		}
		.custom-dropdown {
			margin-right: 10px;
		}
	}
	.type {
		@include for-phone-only {
			width: 100%;
		}
	}
	.row {
		display: flex;
		justify-content: space-between;
		@include for-phone-only {
			flex-direction: column;
		}
	}
	&-form {
		padding: 0;
		flex: 1;

		&-head {
			display: flex;
			justify-content: space-between;
			width: 100%;
			@include for-tablet-under {
				align-items: center;
			}
			.back {
				color: #434343 !important;
				margin-right: 10px;
			}
			strong {
				vertical-align: middle;
				display: flex;
				align-items: center;
				margin: 0;
			}
			.button {
				margin-right: 15px;
				&:last-child {
					margin: 0;
				}
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			margin-top: 50px;
			margin-bottom: 10px;
			color: $color-text;
			@include for-phone-only {
				margin-top: 20px;
			}
		}
		&-type {
			.row {
				.dropdown {
					width: 150px;
				}
			}
		}
	}
	.dropdown {
		@include for-phone-only {
			margin-bottom: 20px;
			width: calc(100% - 20px) !important;
		}
	}
	.date-row {
		.input {
			margin-right: 5px;
			display: flex;
			align-items: center;
			flex-direction: row;
			@include for-phone-only {
				flex-direction: column;
				align-items: flex-start;
				label {
					display: block;
				}
				input {
					margin-top: 5px;
				}
			}
			label {
				margin: 0 5px 0 0;
				@include for-phone-only {
					margin: 0 5px 0 0;
				}
			}
		}
	}
}

.t-days {
	display: flex;
	justify-content: space-between;
	@include for-tablet-under {
		// margin-top: 20px;
	}
	@include for-phone-only {
		margin-top: 0px;
	}
	&-primary {
		.day {
			background: $color-primary !important;
			color: #fff;
		}
	}
	.day {
		margin-right: 10px;
		&:last-child {
			margin: 0;
		}
		// border: 1px solid $color-gray-dark;
		background: $color-gray-dark;
		@include full-center();
		color: #fff;
		padding: 5px;
		border-radius: 7px;
		cursor: pointer;
		opacity: 0.4;
		width: 40px;
		height: 40px;
		font-weight: 600;
		@include for-tablet-under {
			width: 35px;
			height: 35px;
		}

		@include for-phone-only {
			padding: 0;
			font-size: 14px;
		}

		&-active {
			opacity: 1 !important;
		}
	}
}

.timeslot-button {
	border-bottom: 1px solid darken($color-gray, 10);
	// margin-bottom: 20px;

	padding: 20px;
	cursor: pointer;
	text-decoration: none;
	color: #000;
	&-active {
		background-color: $color-primary;
		.day {
			border-color: #fff;
			background: #fff;
			color: $color-gray-dark;
		}
		.timeslot-button-info {
			p {
				color: #fff;
			}
		}
	}
	&-date {
		border: 1px solid darken($color-gray, 10);
		position: relative;
		background: $color-gray-dark;
		color: #fff;
		border-radius: 7px;
		height: 40px;
		@include full-center();
		p {
			font-weight: 600;
			color: #fff;
		}
		.icon {
			position: absolute;
			left: 10px;
			display: flex;
			align-items: center;
			top: 0;
			bottom: 0;
			img {
				height: 22px;
			}
		}
	}
	&-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0 0 0;
	}
}

.tp2-choice-modal {
	.choice-row {
		display: flex;
		flex-wrap: wrap;
		.col {
			flex: 1;
			@include full-center();
			justify-content: flex-start;
		}
		img {
			max-height: 180px;
		}
		.styled-text {
			text-align: center;
			max-width: 80%;
			flex: 1;
			margin: 10px 0;
		}
	}
}
