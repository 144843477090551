@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.checkbox {
	width: 24px;
	height: 24px;
	@include full-center();
	border: 2px solid $color-gray;
	border-radius: 8px;
	cursor: pointer;
	transition: 0.4s ease all;
	&-checked {
		border-color: $color-primary;
		i {
			opacity: 1 !important;
			color: $color-primary !important;
		}
	}
	i {
		opacity: 0;
		color: $color-gray;
		transition: 0.4s ease all;
	}
	&-wrapper {
		display: flex;
		align-items: center;
	}
	&-label {
		font-size: 12px;
		margin-left: 5px;
	}
}
