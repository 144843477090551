@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";
.tab-nav {
	width: 100%;
	border-bottom: 1px solid #eae9ea;
	display: flex;
	align-items: center;
	@include for-tablet-under {
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		.tab-nav-items {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	&-items {
		display: flex;
		flex: 1;
	}
	&-item {
		padding: 20px;
		display: block;
		position: relative;
		background: transparent;
		transition: 0.4s ease;
		@include for-tablet-under {
			width: max-content;
		}
		&:hover {
			// background: darken(#fff, 2%);
			p {
				color: #5a603e;
			}
			&::before {
				background: #979f73;
			}
		}
		&::before {
			content: "";
			display: block;
			height: 2px;
			width: 100%;
			background-color: transparent;
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			transition: 0.4s ease;
		}
		&-active {
			p {
				color: #5a603e;
			}
			&::before {
				background-color: #979f73;
			}
		}
	}
}
