@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.timeslot-list {
	&-button {
		@include full-center();
		padding: 15px;
	}
	&-head {
		padding: 15px;
		@include shadow();
		@include for-tablet-under {
			padding: 15px 0;
		}
	}
	&-items {
		margin-top: 15px;
		flex: 1;
		display: flex;
		min-height: 500px;
		flex-direction: column;
		.container-small {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
	&-loading {
		@include full-center();
		flex: 1;
	}
}

.empty-timeslot {
	@include full-center();
	flex: 1;
	padding: 15px;
	i {
		font-size: 50px;
		color: $color-primary;
	}
	&-label {
		margin: 10px 0;
	}
	&-sublabel {
		opacity: 0.4;
	}
	p {
		text-align: center;
	}
}

.timeslot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	border-bottom: 1px solid $color-gray;
	cursor: pointer;
	transition: 0.4s ease background;
	.badge {
		border: 2px solid $color-background;
	}
	&-text {
		display: block;
		p {
			display: block;
		}
	}
	&-sublabel {
		opacity: 0.4;
		font-size: 14px;
	}
	&-disabled {
		opacity: 0.4;
		pointer-events: none;
	}
	&-active {
		border-bottom: 1px solid $color-primary;
		background: $color-primary;
		&:hover {
			background: $color-primary !important;
		}
		i,
		p {
			color: $color-background;
		}
	}
	&:hover {
		background: $color-gray;
	}
}
