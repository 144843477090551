@import "../../styles/vars.scss";

.switch-wrapper {
	display: flex;
	align-items: center;
}

.switch-label {
	margin-right: 10px;
}
.switch-off.switch-active {
	background: #c0260e !important;
}
.switch {
	border-radius: 4px;
	border: 2px solid $color-gray;
	box-sizing: content-box;
	display: flex;

	&-item {
		display: block;
		padding: 1px 10px;
		cursor: pointer;
		border-radius: 4px;
		text-transform: uppercase;
		font-size: 14px;
	}
	&-active {
		background: $color-primary;
		color: #fff;
	}
}
