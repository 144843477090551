@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.search {
	max-width: 760px;
	margin: 0 auto;
	display: block;
	background: #f0f0f075;
	padding: 40px;
	border-radius: 7px;

	h1 {
		width: 100%;
		color: $color-text;
		font-size: 30px;
		font-weight: 100;
		text-align: center;
		margin-bottom: 20px;
		@include for-tablet-under {
			text-align: center;
			font-size: 22px;
		}
	}
}

.search-box {
	display: flex;
	width: 100%;
	padding: 0;
	border-radius: 12px;
	@include for-tablet-under {
		flex-direction: column;
	}
	.button {
		height: $search-box-height;
		font-size: 18px;
		letter-spacing: 2px;
		margin-left: 10px;
		text-transform: none;
		font-weight: 200;
		border: 0px;
		@include for-tablet-under {
			margin: 20px 0 0 0;
			@include full-center();
		}
	}

	&-input {
		flex: 1;
		position: relative;
		display: flex;

		&-wrapper {
			flex: 1;
		}
		input {
			outline: none;
			font-size: 18px;
			font-weight: 200;
			border: 0px;
			font-family: "Poppins", sans-serif;
			box-sizing: border-box !important;
			padding: 10px;
			border-radius: 6px 0 0 6px;
			flex: 1;
			height: $search-box-height;
			display: block;
			width: 100%;
			color: #434343;
			@include for-tablet-under {
				font-size: 16px;
			}
			&::placeholder {
				font-weight: 200 !important;
			}
		}
	}
}

.google-input {
	position: relative;
	display: flex;
	&-empty {
		border-radius: 6px !important;
	}
	.icon {
		cursor: pointer;
		// width: $search-box-height;
		padding: 20px;
		height: $search-box-height;
		justify-content: center;
		color: #434343;
		display: flex;
		align-items: center;
		font-size: 20px;
		border-radius: 0 6px 6px 0;
		@include for-tablet-under {
			font-size: 16px;
			padding: 10px;
		}
		background-color: rgba(#fff, 20%);
	}
}
