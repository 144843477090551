@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.sub-box {
	border-bottom: 1px solid #eae9ea;
	margin-bottom: 30px;
}

.invoices-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	h2 {
		color: #433;
		font-size: 22px;
		font-weight: 500;
	}
	&-items {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	&-item {
		margin-top: 20px;
		border-radius: 6px;
		padding: 20px;
		display: flex;
		border-bottom: 1px solid #eae9ea;
		transition: 0.4s linear all;
		align-items: center;
		justify-content: space-between;
		&:hover {
			background: #f0f0f0;
		}
		.pdf-icon {
			cursor: pointer;
			img {
				width: 32px;
			}
		}
		&-info {
			p {
				margin-right: 20px;
			}
			@include for-phone-only {
				margin-bottom: 5px;
			}
		}
		@include for-phone-only {
			flex-direction: column;
		}
		strong {
			margin-right: 15px;
		}
	}
	&-empty {
		@include full-center();
		flex: 1;
	}
}

.button-row-account {
	@include for-phone-only {
		display: flex;
		flex-direction: column-reverse;
		.button {
			justify-content: center;
			text-align: center;
			margin-bottom: 10px;
			width: 100%;
		}
	}
}

.account-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100%;
	.icon {
		margin-bottom: 20px;
		i {
			color: $color-primary;
			font-size: 64px;
		}
	}
	p {
		opacity: 0.6;
	}
}

.save-button {
	display: flex;
	justify-content: flex-end;
	padding: 15px 30px;
}

.restaurant-edit-view {
	.card-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}
}

.account-view {
	// margin-top: 30px;
	@include grid(2);
	@include for-tablet-under {
		grid-template-columns: repeat(1, 1fr);
	}
	.info-row {
		flex-wrap: wrap;
		.input,
		.dropdown {
			@include for-phone-only {
				width: 100%;
				margin: 0 0 20px 0;
			}
		}
	}
	.card {
		margin-top: 30px;
		&-info {
			padding: 0 20px;
			@include for-phone-only {
				padding: 0 !important;
			}
		}
		.styled-text-bold {
			margin-top: 20px;
			margin-bottom: 20px !important;
			font-size: 20px;
			color: $color-text;
		}
	}
	.input,
	.dropdown {
		margin-bottom: 20px;
	}
	.button-row {
		display: flex;
		justify-content: space-between;
	}
	.buttons {
		margin-top: 20px;
		display: flex;
		.button {
			margin-right: 20px;
			@include for-tablet-under {
				margin-bottom: 20px;
				width: 100%;
			}
		}
	}
	.reset-password-view-form {
		width: 100%;
		.input {
			margin-top: 0;
		}
	}
}

$imgSize: 135px;

.avatar {
	img {
		width: $imgSize;
		border-radius: 8px;
		height: $imgSize;
		object-fit: contain;
	}
	.file-upload-result {
		max-width: 220px;
	}
}

.restaurant-gallery {
	display: flex;
	flex-wrap: wrap;
	.image-upload {
		margin-right: 20px;
		margin-bottom: 20px;
		img {
			width: $imgSize;
			border-radius: 8px;
			height: $imgSize;
			object-fit: cover;
		}
	}
}

.url-copy {
	.input {
		margin-top: 20px;
		input {
			background-color: $color-gray;
		}
	}
	.button {
		border-radius: 0px 6px 6px 0px;
	}
}

.desktop-buttons {
	@include for-tablet-under {
		display: none !important;
	}
}

.mobile-buttons {
	display: none !important;
	@include for-tablet-under {
		display: flex !important;
	}
}
