@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.register-grid {
	&-title {
		display: block;
		text-align: center;
		font-size: 22px;
	}
	.card {
		background-color: transparent;
		&-info {
			@include for-phone-only {
				padding: 0;
			}
		}
	}
	.info-box {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
	}

	.form {
		margin: 20px 0;
		.pagination {
			color: #fff;
			opacity: 0.3;
		}
		.row {
			@include for-phone-only {
				flex-direction: column;
				.input {
					width: 100%;
				}
			}
		}
		&-fields {
			margin-top: 20px;
		}
		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			padding-bottom: 20px;
			.button-transparent {
				color: #fff;
				font-weight: 100;
				i {
					font-size: 24px;
				}
			}
		}
		.login-cta {
			padding: 20px 0;
			@include full-center();
			color: #fff;
			p {
				margin: 0;
			}
			.button {
				height: 30px !important;
			}
		}
		.agree-cta {
			padding: 20px 0;
			@include full-center();
			color: #fff;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			text-align: center;
			a {
				text-decoration: none;
			}
		}
	}
	.info-account {
		margin-top: 30px;
	}

	.row {
		display: flex;
		.input {
			margin-right: 15px;
			&:last-child {
				margin: 0;
			}
		}
		.dropdown {
			width: auto;
		}
	}
	.full {
		flex: 1;
	}
	input,
	.dropdown {
		margin-bottom: 15px;
	}

	.disclaimer {
		margin: 10px 0 10px;
		font-size: 12px;
	}
}

.register-view {
	display: flex;
	flex-direction: column;
	flex: 1;
	-webkit-flex: 0 1 100%;
	height: 100%;
	background: $color-secondary;
	padding-bottom: 60px;
	.container-small {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.styled-text,
	a {
		color: #fff;
	}
	&-loading {
		color: #fff;
		flex: 1;
		@include full-center();
	}
}
