@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.shadow {
	position: fixed;
	background: #000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 98;
}

.modal {
	position: fixed;
	top: 80px;
	left: 50%;
	right: 0;
	transform: translateX(-50%);
	z-index: 999;
	max-width: 824px;
	width: 90%;

	@include for-tablet-under {
		left: 0;
		top: 30px;
		transform: translateX(0);
		width: 100% !important;
	}
	@include for-phone-only {
		top: 0;
		bottom: 0;
		background: #fff;
	}
	&-has-alert {
		.modal-body {
			padding-top: 0;
		}
	}

	&-small {
		// max-width: 800px !important;
		.modal-head {
			// padding: 20px 32px;
			height: 56px !important;
			h1 {
				// font-weight: 400;
				// font-size: 18px;

				height: 24px !important;
				font-size: 16px !important;
				font-weight: bold !important;
				letter-spacing: 0.16px !important;
				line-height: 24px !important;
			}
			i {
				font-size: 22px;
				cursor: pointer;
			}
		}
	}

	&-wrapper {
		border-radius: 4px;
		background: #fff;
		width: 100%;
		@include for-tablet-under {
			margin: 0 auto;
		}
		@include for-phone-only {
			// height: 100vh;
			min-height: 100vh;

			display: flex;
			flex-direction: column;
			flex: 1;
		}
	}
	&-head {
		border-radius: 4px 4px 0 0;
		padding: 16px 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: $color-background;
		box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.08);
		@include for-tablet-under {
			// flex-direction: column;
		}
		i {
			font-size: 20px;
			cursor: pointer;
		}
		h1 {
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;
		}
		h1,
		h2,
		h3 {
			margin: 0;
			word-break: break-all;
		}
		textarea,
		input {
			font-family: Lato;
			height: 24px;
			resize: none;
			font-size: 20px;
			font-weight: 900;
			letter-spacing: 0.2px;
			line-height: 24px;
			border: 0px;
			flex: 1;
			margin-left: 0;
			padding-left: 0;
			outline: none;
			&::placeholder {
				font-style: italic;
				font-weight: 900;
				opacity: 0.5;
			}
			@include for-tablet-under {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}
	&-popup {
		background: $color-gray;
		border: 1px solid $color-gray;
		padding: 7px;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
		box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.08);
		p {
			font-size: 14px;
			font-weight: bold;
			line-height: 16px;
			color: #333;
		}
		&_success {
			background: #dfedc7;
			border: 1px solid #8ad112;
		}
	}
	&-body {
		padding: 24px 32px 24px 32px;
		min-height: calc(70vh - 96px - 160px);
		max-height: 80vh;

		overflow-y: scroll;
		overflow-x: hidden;
		// display: flex;
		// flex-direction: column;
		position: relative;
		@include for-phone-only {
			flex: 1;
			-webkit-flex: 0 1 100%;
			background: #fff;
			max-height: calc(100vh - 56px);
			max-height: calc((var(--vh, 1vh) * 100) - 56px);
		}
		&_loading {
			@include full-center();
			flex: 1;
		}

		&_content {
			display: flex;
			flex-direction: column;
			flex: 1;
			-webkit-flex: 0 1 100%;
			@include for-phone-only {
				height: calc(
					100vh - 56px
				); /* Fallback for browsers that do not support Custom Properties */
				height: calc((var(--vh, 1vh) * 100) - 56px);
				background: #fff;
				overflow-y: scroll !important;
				// min-height: calc(90vh - 56px);
			}
			.card {
				@include for-phone-only {
					box-shadow: none;
				}
			}
		}
	}
	.timeslot-picker .buttons {
		@include for-phone-only {
			padding-bottom: 30px;
		}
	}
	&-footer {
		// padding: 12px 0 24px 32px;
		position: sticky;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		right: 0;
		bottom: 12px;
		.button:nth-child(2n) {
			margin-left: 16px;
		}
	}
}
