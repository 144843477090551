@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
.rcm {
	margin-top: 30px;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
	text-decoration: none;
	@include for-phone-only {
		width: 100%;
		flex-direction: column;
	}
	&-media {
		height: 200px;
		max-width: 280px;
		width: 100%;
		@include for-phone-only {
			max-width: calc(100vw - 40px);
		}
		.placeholder {
			width: 100%;
			background: $color-primary;
			height: 200px;
			@include full-center();
			color: #fff;
			text-decoration: none;
		}
		img {
			width: 100%;
			height: 200px;

			object-fit: cover;
		}
		.slide-show {
			height: 200px;
			img {
				height: 200px;
				object-fit: cover;
			}
		}
	}

	&-name {
		&-row {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
		}
		.address-distance {
			background: $color-gray;
			color: $color-gray-dark;
			border-radius: 7px;
			padding: 5px;
			margin-left: 15px;
			@include for-phone-only {
				display: none;
			}
		}
	}

	&-info {
		flex: 1;
		padding: 25px 25px;
		position: relative;
		display: flex;
		flex-direction: column;
		.row {
			flex: 1;
		}
		h1 {
			@include for-phone-only {
				font-size: 18px;
			}
		}
		.actions {
			display: flex;
			@include for-phone-only {
				position: absolute;
				right: 25px;
				top: -51px;
			}
		}
		.address {
			margin-right: 15px;
			display: flex;
			flex-direction: column;
			p,
			i {
				color: $color-gray-dark;
				word-break: break-all;
			}
			i {
				margin-right: 5px;
			}
			&-mobile-distance {
				background: $color-gray;
				color: $color-gray-dark;
				border-radius: 7px;
				padding: 5px;
				margin-left: 15px;
				margin-top: 10px;
				width: max-content;
				display: none;
				@include for-phone-only {
					display: inline-block;
				}
			}
		}
	}
	.row {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@include for-phone-only {
			flex-direction: column;
		}
	}
	.count {
		background-color: $color-secondary;
		border-bottom: 3px solid rgba(0, 0, 0, 0.4);
		border-radius: 8px;
		width: 46px;
		height: 46px;
		@include full-center();
		@include for-phone-only {
			margin-top: 20px;
		}
		&-disable {
			background: $color-text;
		}
		strong {
			color: #fff;
		}
	}
	.slick-arrow {
		background: #fff;
		display: flex;
		height: 100%;
		z-index: 22;
		top: 0;
		bottom: 0;
		align-items: center;
		transform: none;
	}
	.time-slider {
		margin-top: 20px;
		max-width: 440px;
		position: relative;
		&-items_hide_arrow {
			.slick-list {
				padding: 0 !important;
			}
		}
		.slick-list {
			max-width: 420px;
			padding: 0 20px;
			@include for-phone-only {
				max-width: 275px;
			}
		}

		.slick-track {
			margin: 0 !important;
		}
		.slick-slide {
			padding-right: 10px !important;
		}
		&-item {
			position: relative;
			padding: 10px 10px;
			border-radius: 7px;
			background: $color-secondary;
			color: #fff;
			text-align: center;
			font-size: 14px;
			width: max-content;
			word-break: keep-all;
			.end {
				@include for-phone-only {
					display: none;
				}
			}
		}
	}
}

.count img {
	width: 35%;
}
