@import "../../styles/vars.scss";
.badge {
	background-color: $color-primary;
	display: inline-block;
	color: #fff;
	border-radius: 30px;
	padding: 3px 12px;
	font-size: 14px;
	&-gray {
		background-color: $color-gray;
		color: $color-text;
	}
	&-white {
		color: $color-text;
		background: #fff;
	}
}

.badges {
	display: flex;
	align-items: center;
	.badge {
		margin-right: 6px;
	}
}
