@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.instructions {
	background-color: $color-gray;
	border-radius: 12px;
	&-grid {
		// @include grid(4);
		// @include for-tablet-under {
		// 	@include grid(1);
		// }
	}
}

.instruction {
	@include full-center();
	position: relative;
	padding: 20px;
	@include for-tablet-under {
		padding-bottom: 40px;
		padding: 20px;
	}
	&-last {
		.instruction-angle {
			display: none;
		}
	}
	&-wrapper {
		display: flex;
		width: 100%;
		margin-bottom: 10px;
	}
	&-badge {
		margin-left: -40px;
		span {
			font-size: 20px;
			@include circle(44px);
			@include full-center();
			background-color: $color-secondary;
			color: #fff;
			font-size: 24px;
			font-weight: 600;
		}
	}

	img {
		height: calc(158px / 2);
	}
	p {
		margin-top: 40px;
		text-align: center;
		max-width: 200px;
	}
	&-angle {
		position: absolute;
		right: 0;
		top: 100px;
		@include for-tablet-under {
			display: none;
		}
		i {
			color: #a8a8a8;
			font-size: 48px;
			@include for-tablet-under {
				transform: rotate(90deg);
			}
		}
	}
}
