@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.timeslot-picker {
	display: flex;
	flex: 1;
	position: relative;
	-webkit-flex: 0 1 100%;
	@include for-phone-only {
		display: block;
	}

	&-box:first-child {
		border-radius: 6px 0px 0px 6px;
		width: 45%;
		-webkit-flex: 0 1 100%;
		@include for-phone-only {
			width: 100%;
			border-radius: 0;
		}
	}
	&-box:last-child {
		border-radius: 0px 6px 6px 0px;
		flex: 1;
		-webkit-flex: 0 1 100%;
		@include for-phone-only {
			border-radius: 0;
		}
	}

	&-box {
		// background: $color-background;
		display: flex;
		flex-direction: column;

		.card {
			flex: 1;
			-webkit-flex: 0 1 100%;
			display: flex;
			flex-direction: column;
		}
		.card-info {
			padding: 0 !important;
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
}

.timeslot-picker-has-timeslot {
	.timeslot-picker-box-form {
		@include for-phone-only {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 999;
			background: $color-background;
		}
		.timeslot-form-info {
			@include for-phone-only {
				margin-top: 0;
			}
		}
	}
}
