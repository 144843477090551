@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.for-partners-header {
	padding-top: 50px;
	&-info {
		@include full-center();
		padding: 80px 0;
		.button {
			margin-top: 40px;
		}
	}
	&-image {
		img {
			width: 100%;
		}
		margin-bottom: 80px;
	}
}

.for-partners-checklist {
	background: #f0f0f0;
	padding: 80px 0;
	.container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 15px;
		@include for-tablet-under {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	&-image {
		@include full-center;
		@include for-tablet-under {
			margin-bottom: 20px;
		}
		img {
			transform: rotate(-17deg);
			width: 300px;
			max-width: 100%;
		}
	}
	&-info {
		ul {
			list-style: none;
			li {
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				i {
					margin-right: 20px;
					color: $color-secondary;
					font-size: 22px;
				}
				p {
					display: block;
					font-size: 16px;
					font-weight: 100;
				}
				.description {
					color: #5a603e;
					display: block;
				}
			}
		}
		.buttons {
			@include full-center();
			padding: 20px 0 0 0;
		}
	}
}
