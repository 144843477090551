@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.subscription-box {
	margin: 30px 0;
	.partner-name {
		font-size: 20px;
		.name {
			font-weight: 600;
			color: $color-primary;
		}
		.partner {
			color: $color-gray-dark;
		}
	}
	.info {
		display: flex;
		@include for-tablet-under {
			flex-direction: column;
		}
		.status {
			margin-bottom: 5px;
		}
		&-box {
			flex: 1;
			margin-top: 10px;
			p {
				display: block;
				max-width: 500px;
			}
		}
		&-buttons {
			@include full-center();
			@include for-tablet-under {
				align-items: flex-start;
				padding: 20px 0;
			}
			.button {
				margin-bottom: 20px;
				min-width: 160px;
				text-align: center;
				@include full-center();
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}
