@import "../../styles/vars.scss";
.logo {
	font-weight: bold;
	font-size: 26px;
	max-width: 140px;
	color: $color-background;
	transition: 0.4s ease color;
	&-primary {
		color: $color-primary;
	}
}
