@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

$admin-nav-width: 280px;

$admin-nav-header-height: 66px;

.layout-admin {
	main {
		flex: 1;
		margin-left: $admin-nav-width;
		max-width: calc(100vw - #{$admin-nav-width});
		@include for-tablet {
			max-width: calc(100vw - 100px);
			width: 100% !important;
			margin: 0;
		}
		@include for-phone-only {
			max-width: 100vw;
			margin: $admin-nav-header-height 0 0 0;
		}
	}
}

.admin-nav-header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 98;
	background: $color-background;
	box-shadow: 0px 3px 66px #5a603e20;

	display: none;
	@include for-phone-only {
		display: flex;
	}
	.container {
		height: $admin-nav-header-height;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-logo {
		display: flex;
		align-items: center;
		@include for-tablet-under {
			display: none;
		}
		&_mobile {
			display: none;
			@include for-tablet-under {
				display: flex;
			}
			strong {
				font-size: 20px;
			}
		}
	}
	img {
		width: 120px;
	}
}

.toggle-button {
	// position: absolute;
	// right: -45px;
	// top: 10px;
	// padding: 15px;
	width: 45px;
	height: 45px;
	@include full-center();
	border-radius: 0 10px 10px 0;
	cursor: pointer;

	path {
		stroke: $color-primary !important;
	}
}

.admin-nav-slided {
	z-index: 999 !important;
}

.admin-nav-expanded {
	background: red;
	z-index: 999;
	@include for-tablet {
		// display: none !important;
	}
	.expand-icon {
		margin-left: 8px;

		justify-content: flex-end !important;
	}
	.admin-nav-item {
		justify-content: flex-start;
		.icon {
			margin-right: 20px !important;
		}
		p {
			display: flex;
		}
	}
}

.admin-nav {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 98;
	width: $admin-nav-width;
	@include for-tablet {
		width: max-content !important;
		position: sticky;
		top: 0;
		height: 100vh;
	}
	@include for-phone-up {
		transform: none !important;
	}
	@include for-phone-only {
		width: 85vw;
	}

	background-color: $color-secondary;
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11);
	.expand-icon {
		color: #fff;
		display: none;
		margin-top: 10px;
		@include for-tablet {
			display: flex;
			justify-content: center;
		}
		path {
			stroke: #fff !important;
		}
	}
	&-logo {
		@include full-center();
		min-height: 200px;
		@include for-tablet {
			min-height: min-content;
			margin: 40px 0 20px;
			display: none;
		}
		img {
			height: 120px;
			@include for-tablet {
				height: 40px;
			}
		}
		.logo {
			font-size: 40px;
		}
	}
	&-items {
		flex: 1;
		display: flex;
		padding: 15px;
		flex-direction: column;
		&-spacer {
			flex: 1;
		}
	}
	&-button {
		@include full-center();
		padding: 15px 30px;
	}
	&-item {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 12px 20px;
		margin-bottom: 10px;
		text-decoration: none;
		border-radius: 6px;
		transition: 0.4s linear;

		&:hover {
			background-color: lighten($color-primary, 15);
		}
		.icon {
			margin-right: 20px;
			@include for-tablet {
				margin: 0 !important;
			}
			i {
				color: $color-background;
				font-size: 18px;
			}
			img {
				width: 18px;
			}
		}
		p {
			font-weight: 300;
			color: $color-background;
			@include for-tablet {
				display: none;
			}
		}
		&-active {
			background-color: $color-primary;
			i {
				font-weight: bold;
			}
			p {
				font-weight: 500;
			}
		}
	}
}
