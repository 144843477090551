@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.subscriptions-view {
	.header-gray {
		background: #f0f0f0;
		padding: 80px 0 240px;
		.title-box {
			padding-bottom: 40px;
		}

		.radio-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			@include for-phone-only {
				flex-direction: column;
				align-items: flex-start;
				margin-left: 25px;
			}
			.option {
				margin-right: 20px;
				display: flex;
				align-items: center;
				cursor: pointer;
				@include for-phone-only {
					margin-bottom: 10px;
				}
				&:last-child {
					margin-bottom: 0px;
				}
				label {
					color: #434343;
					.light {
						font-weight: 100;
					}
				}
				&-active {
					.radio-circle {
						background: $color-secondary;
					}
				}
				.radio {
					cursor: pointer;
					margin-right: 10px;
					@include circle(20px);
					border: 1px solid #a8a8a8;
					@include full-center();
					background: #fff;
					&-circle {
						@include circle(14px);
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.packages {
		margin-top: -180px !important;
	}
}

.packages {
	@include grid(3);
	@include for-phone-only {
		display: flex;
		flex-direction: column !important;
	}
	.package {
		@include for-phone-only {
			margin-bottom: 50px;
		}
		position: relative;
		background: #fff;
		padding: 20px;
		color: #434343;
		border-radius: 5px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		flex: 1;
		transition: 0.4s ease all;
		&-special {
			background: #434343;
			color: #fff;
			.package-head {
				border-bottom: 1px solid rgba(#fff, 0.1);
			}
			.package-description {
				p {
					opacity: 0.4;
					margin-bottom: 15px;
				}
				p,
				span {
					color: #fff;
					font-weight: 300;
				}
			}
		}
		&:hover {
			transform: translateY(-20px);
		}
		&-head {
			padding: 20px 0 20px;
			border-bottom: 1px solid #f0f0f0;
			height: 160px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&-points {
			flex: 1;
			list-style: none;
			margin-bottom: 20px;
			li {
				display: flex;
				margin-bottom: 20px;
				.icon {
					color: $color-primary;
					margin-right: 15px;
				}
				.info {
					p {
						font-weight: 300;
					}
					span {
						font-weight: 300;
						color: #a8a8a8;
					}
				}
			}
		}
		&-recommended {
			display: flex;
			justify-content: center;
			position: absolute;
			top: -25px;
			left: 0;
			right: 0;
			.button {
				height: 50px;
				text-transform: uppercase;
				// margin-top: -45px;
			}
		}
		&-description {
			padding: 20px 0;
			color: $color-secondary;
			font-weight: 300;
		}
		&-title {
			text-transform: uppercase;
			font-weight: 300;
		}
		.price-info {
			font-weight: 600;
			margin: 10px 0;
			p {
				font-size: 22px;
			}
			.light {
				font-size: 14px;
				font-weight: 300;
			}
		}
		.price {
			font-weight: 600;
			font-size: 44px;
			margin: 10px 0;
			.light {
				font-size: 18px;
				font-weight: 300;
			}
		}
		&-button {
			.button {
				width: 100%;
				text-align: center;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
			}
		}
	}
}
