@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.checkout-view {
	h2 {
		text-align: center;
		color: #434343;
		font-weight: 100;
		margin-bottom: 60px;
		@include for-phone-only {
			font-size: 22px;
			margin-bottom: 40px;
		}
	}
}

.checkout {
	&-head {
		border-radius: 6px 6px 0 0;
		display: flex;
		.checkout-tab {
			flex: 1;
		}
	}
	&-body {
		padding: 50px 45px;
		@include for-phone-only {
			padding: 50px 30px;
		}
		.buttons {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.ideal-element {
	// background: red;
	width: 100%;
	// margin-bottom: 15px;
	border-radius: 6px;
	font-size: 16px;
	background: rgba(255, 255, 255, 0.25);
	font-family: "Gilroy-Light", sans-serif;
	color: #222222;
	border: 1px solid rgba(33, 55, 74, 0.25);
	width: 100%;
	transition: 0.4s ease all;
}

.checkout-tab {
	@include full-center();
	background: $color-gray;
	opacity: 0.5;
	transition: 0.4s ease all;
	// cursor: pointer;
	padding: 15px;
	&:first-child {
		border-radius: 6px 0 0 0;
	}
	&:last-child {
		border-radius: 0 6px 0 0;
	}
	&_active {
		background: #fff !important;
		opacity: 1 !important;
	}
	.icon {
		font-size: 22px;
	}
	.label {
		margin: 5px 0 0 0;
		text-transform: uppercase;
	}
}
