@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.banner {
	position: relative;
	&-slides {
		height: 500px;
		@include for-phone-only {
			height: 400px;
		}
		img {
			height: 500px;
			object-fit: cover;
			@include for-phone-only {
				height: 400px;
			}
		}
	}
	&-large {
		.banner-top {
			padding: 200px 0;
		}
	}
	&-center {
		.banner-top {
			.container-small {
				@include full-center();
			}
		}
	}
	&-empty {
		.banner-content {
			margin: 0 !important;
		}
	}
	&-top {
		color: #fff;
		padding: 120px 0 120px;
		background-color: $color-primary;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		.styled-text-subtitle {
			max-width: 65%;
			@include for-tablet-under {
				max-width: 100%;
			}
		}
	}
	&-content {
		position: relative;
		z-index: 33;
		margin-top: -100px;
	}
}
