@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.title-box {
	padding-bottom: 80px;
	@include full-center();
	p {
		max-width: 700px;
		text-align: center;
		font-size: 20px;
		margin-top: 10px;
	}
	.button {
		margin-top: 40px;
	}
}
