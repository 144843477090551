@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.reset-password-view {
	background: $color-secondary;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	@include full-center();
	.card {
		background: $color-secondary;
		color: #fff;
	}
	&-form {
		width: 600px;
		max-width: 90%;
		.input {
			margin: 20px 0;
		}
	}
}
