.legal {
	h1 {
		color: #434343;
		margin-bottom: 40px;
		font-weight: 600;
	}
	strong {
		font-weight: 600;
	}
	p {
		margin-bottom: 20px;
		display: block;
		font-weight: 100;
		color: #434343;
	}
	ul {
		padding-left: 30px;
		margin-bottom: 20px;
	}
	li {
		font-weight: 100;
		color: #434343;
	}
}
