@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.loading {
	@include full-center();
	flex: 1;
	i {
		font-size: 32px;
		margin-bottom: 20px;
	}
	p {
		opacity: 0.2;
	}
}
