@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.file-upload {
	border: 2px dashed $color-primary;
	@include full-center();
	border-radius: 8px;
	cursor: pointer;
	padding: 0 3em;
	width: 160px;
	border-radius: 8px;
	height: 160px;
	.icon {
		color: $color-primary;
		font-size: 30px;
		text-align: center;
	}
	&-result {
		width: 100%;
	}
}

.image-upload {
	position: relative;
	.icon-delete {
		position: absolute;
		cursor: pointer;
		top: 5px;
		left: 5px;
		background: $color-primary;
		@include circle(30px);
		@include full-center() i {
			color: #fff;
		}
		i {
			font-size: 14px;
		}
	}
}
