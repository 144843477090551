@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

$s-p-top: 200px;

.search-banner {
	.banner-top {
		padding-top: $s-p-top !important;
		padding-bottom: calc(#{$s-p-top} + 120px) !important;
		@include for-phone-only {
			padding-top: calc(#{$s-p-top} - 100px) !important;
			padding-bottom: calc(#{$s-p-top} + 50px) !important;
		}
		// padding-bottom: 260px !important;
	}
	.banner-content {
		margin-top: -120px;
	}
}
