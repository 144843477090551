@import "../../styles/vars.scss";

.dropdown-open {
	.dropdown-options {
		max-height: 200px !important;
		overflow-y: scroll;
		opacity: 1;
		border-color: $color-gray-dark;
		pointer-events: all;
	}
}

.dropdown {
	background-color: #fff;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
	height: $input-height;
	border-radius: 5px;
	border: 1px solid $color-gray-dark;
	color: $color-gray-dark;
	font-size: 13.33px;
	cursor: pointer;
	// box-sizing: co;
	position: relative;
	&-search {
		padding: 0px;
		.input {
			flex: 1;
		}
		input {
			background: transparent !important;
			border: 0px !important;

			padding: 0 10px;
		}
	}
	input {
		margin: 0;
	}
	.input,
	input {
		margin: 0 !important;
	}
	.icon {
		margin-left: 10px;
		margin-right: 10px;
	}
	&-error {
		border: 1px solid #d63031 !important;
	}
	&-value {
		.dropdown-placeholder {
			color: $color-text;
			font-size: 13.33px;
		}
	}
	&-placeholder {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			flex: 1;
			display: inline-block;
		}
		.icon {
			font-size: 18px;
		}
	}
	&-options {
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		position: absolute;
		pointer-events: none;
		left: -1px;
		z-index: 55;
		right: -1px;
		top: calc(#{$input-height} - 6px);
		border: 1px solid transparent;
		border-radius: 0px 0px 6px 6px;
		box-shadow: $shadow;
		transition: 0.4s ease max-height;
		background: #fff;
		&-item {
			padding: 10px;
			color: $color-text;
			background: #fff;
			border-bottom: 1px solid $color-gray;
		}
	}
}
