@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.reservation-modal {
	max-width: 1080px;
	.modal-body {
		padding: 0;
	}
	.timeslot-list-head {
		@include for-phone-only {
			padding: 20px;
		}
	}
}
