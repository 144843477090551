@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.subscription-view-whitelist {
	// background: $color-secondary;
	display: flex;
	flex: 1;
	@include for-phone-only {
		flex-direction: column;
	}
	&-header {
		width: 100px;
		height: 100vh;
		object-fit: cover;
		@include for-phone-only {
			max-height: 100px;
			width: 100%;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		@include full-center();
	}
	&-header,
	&-content {
		flex: 1;
	}

	&-loading {
		flex: 1;
		@include full-center();
		background: $color-secondary;
		color: #fff;
		p {
			color: #fff;
		}
	}

	.card-info {
		@include for-phone-only {
			padding: 20px;
		}
	}

	.container-small {
		min-height: 100vh;
		@include full-center();
		@include for-phone-only {
			min-height: 100%;
		}

		.packages {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 15px;
			width: 100%;
			max-width: 600px;
			margin: 40px auto;
			.package {
				// background: $color-primary;
				background: #fff;
				@include full-center();
				cursor: pointer;
				border: 2px solid transparent;
				transition: 0.4s ease;
				&:hover {
					background: lighten($color-primary, 60%);
					transform: scale(1.02);
				}
				&-active {
					// border-color: $color-secondary;
					background: $color-primary;
					strong,
					p {
						color: #fff !important;
					}
					&:hover {
						background: darken($color-primary, 1%);
					}
				}
				strong {
					font-size: 22px;
					margin-bottom: 10px;
				}
				strong,
				p {
					display: block;
					text-align: center;
					color: $color-primary;
				}
			}
		}
	}
}
