@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.reservate-head {
	background: $color-primary;
	padding: 30px 0;
	position: sticky;
	top: 0;
	z-index: 99;
	@include for-phone-only {
		padding: 20px 0;
	}
	.head-icon {
		@include full-center();
		cursor: pointer;
		color: $color-background;
		font-size: 24px;
		height: 60px;
		text-decoration: none;
	}
	h1 {
		color: $color-background;
		@include for-phone-only {
			font-size: 20px;
		}
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.restaurants-slots-view {
	&-body {
		.container-small {
			max-width: 540px;
		}
	}
	.day {
		flex: 1;
	}
}
