@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.timeslot-form-empty,
.timeslot-form-success {
	@include full-center();
	padding: 40px;
	flex: 1;
	i {
		display: block;
		font-size: 50px;
		margin-bottom: 10px;
	}
	h1 {
		text-align: center;
	}
	p {
		display: block;
		text-align: center;
	}
	.button {
		margin-top: 10px;
		text-align: center;
	}
}

.timeslot-form {
	padding: 20px;
	display: flex;
	justify-content: center;
	flex: 1;

	&-info {
		margin-top: 40px;
		margin-bottom: 20px;
		@include grid(2);
		.tab {
			border-radius: 7px;
			text-align: center;
			background: $color-gray;
			padding: 10px;
		}
	}
	&-wrapper {
		width: 400px;
		@include for-phone-only {
			width: 100%;
			padding-bottom: 40px;
		}
		max-width: 100%;
		.input {
			margin-bottom: 20px;
		}
	}
	.household-select {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		p {
			flex: 1;
		}
		.dropdown {
			flex: 1;
			background: $color-gray;
			&-placeholder {
				color: #434343;
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 40px;
		// @include for-phone-only {
		// 	flex-direction: column-reverse;
		// }
	}
}
