.person-slider {
	margin-bottom: 20px;
	max-width: 500px;
	label {
		margin-bottom: 5px;
		font-size: 14px;
		.optional {
			color: #e6e6e6;
		}
	}
	.slick-prev {
		left: 0px !important;
	}
	.slick-next {
		right: 0px !important;
	}
	&-items {
		padding: 0 20px;
		width: 100%;
		overflow-x: scroll;
		// display: flex;
		// align-items: center;
		// padding-bottom: 4px;
		&::-webkit-scrollbar {
			display: none !important;
		}
		.button {
			margin-right: 10px;
			width: calc(100% - 10px);
			justify-content: center;
		}
	}
}
