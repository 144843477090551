@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.loader {
	padding: 3rem;
	@include full-center();
}

.restaurants-view {
	display: grid;
	grid-template-columns: 220px 1fr;
	grid-gap: 30px;
	@include for-tablet-under {
		grid-template-columns: 1fr;
	}
	.secondary {
		color: $color-secondary;
		font-weight: 500;
	}
	&-label {
		border-bottom: 1px solid $color-gray;
		padding-bottom: 10px;
		font-size: 18px;
		font-weight: 300;
		color: #434343;
	}

	.restaurant-card {
		margin-bottom: 20px;
		// width: 100%;
	}
}

.filter-button {
	align-items: center;
	justify-content: center;
	display: none;
	height: 40px;
	background: #484b38;
	@include for-phone-only() {
		display: flex !important;
	}
	.button {
		transform: translateY(-20px);
	}
}
.filter-visible {
	.filter-items {
		max-height: 300px;
	}
}
.filter {
	@include for-phone-only {
		position: fixed;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 97;
	}
	&-items {
		@include for-phone-only {
			max-height: 0;
			overflow: hidden;
			overflow-y: auto;
			transition: 0.7s linear all;
		}
	}
	&-item {
		margin-bottom: 15px;
		@include for-phone-only {
			background: #fff;
			padding: 40px 20px 40px;
			margin-bottom: 0;
		}
	}
	.more {
		color: $color-secondary;
		text-align: right;
		width: 100%;
		font-weight: 200;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}
	&-list {
		list-style: none;
		margin-top: 15px;
		li {
			padding: 5px;
			margin-bottom: 5px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			.count {
				color: $color-secondary;
				font-weight: 300;
			}
			.label {
				display: flex;
				align-items: center;
				.checkbox {
					margin-right: 10px;
					width: 20px;
					height: 20px;
					border-radius: 3px;
					background: #fff;
					&-checked {
						color: $color-secondary;
						border-color: $color-secondary;
					}
				}
				.name {
					color: $color-gray-dark;
				}
			}
		}
	}
}
