@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.suggestions {
	// background-color: $color-gray;
	&-title {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-items {
		.slick-slide div[tabindex]:first-child {
			display: block !important;
		}
		.restaurant-card {
			margin: 10px;
			@include for-tablet-under {
			}
		}
	}
	// &-items {
	// 	@include grid(3);
	// 	@include for-tablet-under {
	// 		@include grid(1);
	// 	}
	// }
}
