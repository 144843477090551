@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.refetch-icon {
	padding: 0 14px;
	cursor: pointer;
}

.refetch-tablet {
	@include for-tablet-under {
		display: none;
	}
}

.dashboard-view {
	// .layout-title {
	// 	border-bottom: 1px solid $color-gray;
	// 	padding-bottom: 10px;
	// }

	.heads {
		font-size: 20px;
		color: $color-text;
		margin: 15px 0 0 0;
	}
	.greeting {
		display: flex;
		align-items: center;
		width: max-content;
		margin-bottom: 20px;
		@include for-tablet-under {
			justify-content: space-between;
			width: 100%;
		}
		.refetch-icon {
			@include for-tablet-up {
				display: none;
			}
		}
		p,
		strong {
			display: block;
		}
		&-title {
			color: $color-gray-dark;
			font-size: 22px;
		}
		&-subtitle {
			text-align: right;
			@include for-phone-only {
				text-align: left;
			}
		}
	}

	.sub-box {
		background: $color-gray;
		display: flex;
		height: max-content;
		flex-direction: column;
		border-radius: 7px;
		padding: 5px 15px;
		text-align: right;
		@include for-tablet-under {
			display: none;
		}
	}

	.row {
		display: flex;
		justify-content: space-between;
		@include for-phone-only {
			flex-wrap: wrap;
		}
	}

	.grid {
		@include grid(3);
		margin-top: 40px;
		@include for-tablet-under {
			grid-template-columns: repeat(2, 1fr);
		}
		@include for-phone-only {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	.stat-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: $color-gray;
		border-radius: 7px;
		padding: 15px;
		&-count {
			background: $color-secondary;
			font-size: 24px;
			@include circle(40px);
			@include full-center();
			color: #fff;
		}
	}
	.month {
		margin-top: 40px;
		&-head {
			display: flex;
			margin-bottom: 40px;
			justify-content: space-between;
		}
		&-body {
			margin: 0 -10px;
		}
	}
	.month-stat-block {
		border-radius: 7px;
		margin-right: 20px;
		@include for-phone-only {
			margin: 0 10px !important;
		}
		&-head {
			border-radius: 7px 7px 0 0;
			@include full-center();
			background: $color-primary;
			color: #fff;
			padding: 10px;
			strong {
				color: #fff;
			}
		}
		&-count {
			background: $color-secondary;
			color: #fff;
			border-radius: 7px;
			padding: 5px 25px;
		}
		ul {
			list-style: none;
			padding: 0;
			li {
				display: flex;
				justify-content: space-between;
				padding: 20px;
				background: $color-gray;
				border: 1px solid $color-gray-dark;
				border-top: 0px;
				&:last-child {
					border-radius: 0px 0px 7px 7px;
				}
			}
		}
	}
}
