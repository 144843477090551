@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.alert-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 99999;
	@include full-center();
}

.alert {
	padding: 40px;
	@include full-center();
	background-color: $color-background;
	border-radius: 6px;
	@include shadow();
	width: 400px;
	max-width: 90%;
	&-subscription-cancel {
		width: 600px;
	}
	&-confirm {
		width: 500px;
	}
	&-title {
		text-align: center;
		font-size: 22px;
	}

	&-description {
		margin-top: 20px;
		text-align: center;
	}
	.button {
		margin-top: 20px;
	}

	&-buttons {
		display: flex;
		align-items: center;
		margin-top: 20px;
		.button {
			margin-right: 20px;
			margin-top: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
}
