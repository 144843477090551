@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.reservation-detail-modal {
	display: flex;
	flex: 1;
	@include for-phone-only {
		flex-direction: column;
	}
	label,
	.label {
		color: $color-primary;
		font-weight: 500 !important;
	}
	.user-info {
		width: 300px;
		padding-left: 20px;
		justify-content: center;
		display: flex;
		flex-direction: column;
		border-right: 1px solid darken($color-gray, 10);
		@include for-phone-only {
			border: 0;
			padding-left: 0;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-gray;
		}
		label {
			color: $color-primary;
		}
		.styled-text {
			margin: 0 0 5px 0;
			display: block;
		}
	}
	.info {
		padding: 30px;
		width: 100%;
		@include for-phone-only {
			padding: 0;
		}
		&-part {
			padding: 10px 0;
			border-bottom: 1px solid $color-gray;
			&-no-border {
				border-bottom: 0px !important;
			}
			&-block {
				.styled-text {
					display: block;
				}
			}
		}
		.row {
			display: flex;
			justify-content: space-between;
			margin-top: 3px;
			&-justify {
				label {
					text-align: left !important;
				}
			}
			label {
				text-align: right;
				padding-right: 10px;
			}
			.styled-text {
				flex: 1;
			}
		}
		.badge {
			border-radius: 5px;
		}
		.person-count {
			height: 30px;
		}
		.cta-buttons {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			@include for-phone-only {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				// padding-bottom: 20px;
				background: #484b38;
				.button {
					transform: translateY(-22.5px);
				}
			}
			.button {
				margin-right: 15px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
