@import "../../styles/vars.scss";
.map {
	height: 400px;
	width: 100%;
	display: block;
	position: relative;
	&-overlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		pointer-events: none;
		background: transparent;
		z-index: 22;
	}
	.marker {
		i {
			font-size: 22px;
			color: $color-primary;
		}
	}
}
