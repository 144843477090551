@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.layout-no-line {
	.layout-head {
		border-bottom: 0px !important;
	}
}

.layout-mobile-button {
	display: none;
	@include for-phone-only {
		display: flex;
		margin-bottom: 20px;
	}
}

.layout-admin {
	display: flex;
	flex: 1;

	main {
		padding: 60px;
		@include for-tablet-under {
			padding: 40px 20px;
		}
		display: flex;
		flex-direction: column;
		background: $color-background;
	}
	&-loading {
		@include full-center();
		flex: 1;
	}
	.layout-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;
		// margin-bottom: 20px;
		padding-bottom: 10px;
		@include for-tablet-under {
			display: none;
		}
		&:empty {
			display: none;
		}
	}
	.layout-title {
		font-size: 24px;
		display: flex;
		font-weight: 500;
		color: #433;

		@include for-tablet-under {
			display: none;
		}
		.icon {
			cursor: pointer;
			margin-right: 10px;
		}
	}
}

.layout {
	padding-top: $nav-height;
	display: flex;
	flex-direction: column;
	flex: 1;
	@include for-phone-only {
		padding-top: $nav-height-mobile;
	}
	&-loading {
		@include full-center();
		width: 100%;
		height: calc(100vh - #{$nav-height});
	}
}
