@import "../../styles/mixins.scss";
@import "../../styles//vars.scss";

.styled-text {
	color: currentColor;
	display: inline-block;
	&-paragraph {
		font-weight: 100;
		color: $color-text;
	}
	&-title {
		font-weight: 500;
		color: #434343;
		@include for-phone-only {
			font-size: 28px;
		}
	}
	&-bold {
		font-weight: 500;
		color: #434343;
	}
	&-label {
		font-weight: normal;
	}
	&-subtitle {
		font-weight: 600 !important;
		color: #434343;
		font-size: 40px;
		@include for-phone-only {
			font-size: 20px;
		}
	}
}
