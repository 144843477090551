@import "../../styles/vars.scss";

.button {
	background: $color-primary;
	color: $color-background;
	font-weight: 300;
	padding: 0 30px;
	cursor: pointer;
	// text-transform: uppercase;
	height: $button-height;
	// border-radius: 40px;
	border-radius: 5px;
	border-top: 3px solid transparent;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-bottom: 3px solid rgba(0, 0, 0, 0.4);
	font-size: 14px;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.4s ease-in-out all;
	text-decoration: none;
	text-align: center;
	&-no-uppercase {
		text-transform: none;
		font-weight: 300;
	}
	&-flip-icon {
		i {
			transform: rotate(90deg);
		}
	}
	&-is-svg {
		padding: 0 15px;
	}
	img {
		width: 22px;
		height: 22px;
		object-fit: contain;
	}
	&-empty {
		i {
			margin: 0 !important;
		}
	}

	&:hover {
		border-bottom-color: rgba(0, 0, 0, 0.1);
		opacity: 0.9;
	}
	&-outline {
		background-color: transparent;
		border: 2px solid $color-primary;
		color: $color-primary;
		&:hover {
			background: $color-primary;
			border-bottom-color: $color-primary;
			color: #fff;
		}
	}
	&-disabled {
		pointer-events: none;
		opacity: 0.4;
	}
	&-loading {
		pointer-events: none;
		opacity: 0.8;
	}
	&-gray {
		color: $color-text;
		background-color: $color-gray;
		border-bottom-color: rgba(0, 0, 0, 0.1);
	}
	&-white {
		background: $color-background;
		color: $color-text;
		border-bottom-color: rgba(0, 0, 0, 0.1);
	}
	&-transparent {
		background: transparent;
		padding: 0;
		border-bottom-color: transparent;
		color: $color-gray-dark;
		&:hover {
			border-bottom-color: transparent !important;
		}
	}
	&-secondary {
		background: $color-secondary;
		border-bottom-color: transparent;
		&:hover {
			border-bottom-color: transparent !important;
		}
	}
	&-icon-left {
		i {
			margin-right: 10px;
			display: inline-block;
		}
	}
	&-icon-right {
		i {
			margin-left: 10px;
			display: inline-block;
		}
	}
}
