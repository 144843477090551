@mixin full-center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@mixin circle($size) {
	width: $size;
	height: $size;
	border-radius: calc(#{$size} / 2);
}

@mixin placeholder() {
	color: $color-gray-dark;
	font-family: "Poppins", sans-serif !important;
	font-weight: 300 !important;
}

@mixin grid($column) {
	display: grid;
	grid-template-columns: repeat($column, 1fr);
	grid-gap: 20px;
}

@mixin shadow() {
	box-shadow: 0px 3px 66px #5a603e20;
}

@mixin for-phone-only {
	@media (max-width: 599px) {
		@content;
	}
}

@mixin for-tablet {
	@media (min-width: 599px) and (max-width: 1036px) {
		@content;
	}
}

@mixin for-tablet-landscape-under {
	@media (max-width: 1036px) {
		@content;
	}
}

@mixin for-phone-up {
	@media (min-width: 599px) {
		@content;
	}
}

@mixin for-tablet-under {
	@media (max-width: 800px) {
		@content;
	}
}

@mixin for-tablet-up {
	@media (min-width: 800px) {
		@content;
	}
}

@mixin for-tablet-portrait-up {
	@media (min-width: 600px) {
		@content;
	}
}
@mixin for-tablet-landscape-up {
	@media (min-width: 900px) {
		@content;
	}
}
@mixin for-desktop-up {
	@media (min-width: 1200px) {
		@content;
	}
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) {
		@content;
	}
}
