@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.navigation-open {
	.navigation-items {
		max-height: 100vh;
		.nav-item {
			@include for-tablet-under {
				color: $color-text;
			}
		}
	}
	.navigation-submenu {
		max-height: 60vh;
		pointer-events: all !important;
		@include for-phone-only {
			left: 0;
			right: 55px;
			// width: 100vw;
		}
	}
}

.navigation-is-white {
	background: #fff !important;
	i {
		color: $color-primary !important;
	}
}

.navigation-scrolled {
	background: $color-primary !important;
	i {
		color: #fff !important;
	}
}

.navigation {
	position: fixed;
	left: 0;
	right: 0;
	background: $color-primary;
	top: 0;
	z-index: 98;
	height: $nav-height;
	display: flex;
	padding: 0 20px;
	align-items: center;
	transition: 0.4s ease;
	@include for-phone-only {
		justify-content: space-between;
		height: $nav-height-mobile;
	}
	&-no-search {
		.navigation-logo {
			justify-content: flex-start !important;
		}
	}
	.mobile-location-icon {
		display: none;
		flex: 1;
		@include for-phone-only {
			display: flex;
		}
		.icon {
			height: $nav-height-mobile;
			justify-content: center;
			align-items: center;
			display: flex;
			padding-right: 20px;
			color: #fff;
			font-size: 1.4em;
			border-right: 1px solid rgba(0, 0, 0, 0.19);
		}
	}
	&-logo {
		display: flex;
		align-items: center;
		flex: 1;
		@include for-phone-only {
			justify-content: center !important;
		}
		img {
			width: 200px;
			max-width: 100%;
			@include for-phone-only {
				width: 120px;
			}
		}
	}

	.nav-location-input {
		flex: 1;
		@include full-center();
		@include for-phone-only {
			display: none;
		}
		p {
			color: #fff;
			text-align: center;
			cursor: pointer;
			border-bottom: 1px solid #fff;
		}
	}

	.icon {
		cursor: pointer;
		color: #fff;
		font-size: 24px;
	}
	&-bars {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		span {
			color: #fff;
			font-weight: 600;
			transition: 0.4s ease color;
		}
	}
	&-items {
		display: flex;
		@include for-tablet-under {
			top: $nav-height;
			position: fixed;
			flex-direction: column;
			left: 0;
			z-index: 99;
			width: 100%;
			max-height: 0;
			overflow: hidden;
			transition: 0.4s ease max-height;
			.nav-item-wrapper {
				margin: 0 !important;
			}
		}
		.nav-item-wrapper {
			margin-right: 40px;
			@include for-tablet-up {
				transform: none !important;
				opacity: 1 !important;
			}
		}
	}
}

.nav-item {
	color: #fff;
	text-decoration: none;
	font-size: 14px;

	@include for-tablet-under {
		width: 100%;
		flex: 1;
		background: $color-background;
		padding: 20px 20px !important;
		height: auto !important;
		border-bottom: 1px solid $color-gray;
		&:first-child {
			border-top: 1px solid $color-gray;
		}
	}
	&-wrapper {
		display: flex;
		align-items: center;
		font-weight: 200;
	}
}

.navigation-submenu {
	top: calc(#{$nav-height} + 30px);
	position: fixed;
	flex-direction: column;
	right: 20px;
	z-index: 99;
	overflow: hidden;
	max-height: 0;
	transition: 0.4s linear;
	box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.19);
	// pointer-events: none;
	@include for-tablet-under {
		top: $nav-height-mobile;
		left: 0;
		right: 0;
	}
	@include for-phone-only {
		max-height: 100vh !important;
		left: -100vw;
		bottom: 0;
		right: auto;
		top: 0;
		background: $color-secondary;
		box-shadow: 0px 0px 135px rgba(0, 0, 0, 0.8) !important;
		display: flex;
		flex: 1;
		flex-direction: column;
	}
	.socials {
		display: none;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		padding-top: 30px;
		flex: 1;
		align-items: center;
		@include for-phone-only {
			display: flex;
		}
		a {
			margin-right: 10px;
			&:last-child {
				margin: 0;
			}
			color: $color-primary;
			border: 2px solid $color-primary;
			@include circle(60px);
			@include full-center();
			text-decoration: none;
			i {
				font-size: 28px;
			}
		}
	}

	.bold {
		@include for-phone-only {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			width: 100%;
			padding-bottom: 10px;
		}
	}
	strong {
		@include for-phone-only {
			margin-top: 10px;
		}
	}

	.bold,
	strong {
		@include for-phone-only {
			font-size: 18px;
		}
	}

	&-items {
		background-color: $color-secondary;
		padding: 20px;
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.nav-item-wrapper {
		display: none;
	}
	strong,
	.sub-item {
		margin-bottom: 10px;
		color: #fff;
		@include for-phone-only {
			padding: 5px;
		}
	}
	.sub-item {
		font-weight: 200;
		display: block;
		width: max-content;
		color: #fff;
		text-decoration: none;
		@include for-phone-only {
			margin-bottom: 20px;
			width: 100%;
		}
		&:last-child {
			@include for-phone-only {
				padding-bottom: 30px;
			}
		}
	}
}

.nav-location-modal {
	.search {
		max-width: 100%;
		width: 100%;
		padding: 0;
		background: transparent;
	}
	.button {
		border: 2px solid $color-primary;
	}
	.google-input {
		height: $search-box-height;
		border: 2px solid $color-gray;
		box-sizing: content-box;
	}
	.search-box {
		.button {
			height: calc(#{$search-box-height} + 4px) !important;
		}
	}
	.autocomplete {
		position: relative;
		max-height: 500px !important;
		margin-top: 0 !important;
		opacity: 1 !important;
		box-shadow: 0px 0px 0px transparent;
		&-wrapper {
			padding: 15px 0;
		}
	}
}
