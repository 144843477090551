@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

.reservations-view {
	&-head {
		display: flex;
		align-items: center;
		margin: 20px 0 0 0;
		@include for-phone-only {
			flex-wrap: wrap;
			position: sticky;
			top: 66px;
			z-index: 99;
			padding: 10px 0;
			background: #fff;
			margin-top: 10px;
		}
		.input {
			margin-left: 10px;
			width: 40% !important;
			@include for-phone-only {
				margin: 10px 0 0 0;
				width: 100% !important;
			}
		}
		.button {
			margin-right: 10px;
			&:last-child {
				margin: 0;
			}
		}
		.reload-button {
			// margin: 0;
			display: flex;
			align-items: center;
		}
		.date-input {
			.react-datepicker-wrapper {
				@include for-phone-only {
					width: 100%;
				}
			}
		}
	}
	.timeslot-form {
		padding: 0;
		width: 100%;
		max-width: 100% !important;
	}
}

.reservations-list {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	&-item {
		padding: 15px;
		border-bottom: 1px solid $color-gray;

		p,
		strong {
			display: block;
		}
		.info-label {
			min-width: 200px;
			display: inline-block;
		}
	}
}

.mobile-table {
	display: none;
	margin-top: 20px;
	@include for-phone-only {
		display: flex !important;
		flex-direction: column;
	}
}

.reservation-table {
	margin-top: 20px;
	@include for-phone-only {
		display: none !important;
	}
	&-empty {
		@include full-center();
		flex: 1;
		width: 100%;
	}
	.actions {
		display: flex;
		.icon {
			margin-left: 10px;
			font-size: 22px;
			color: $color-primary;
			transition: 0.4s ease;
			cursor: pointer;
			img {
				height: 18px;
			}
			&:hover {
				opacity: 0.8;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
	thead {
		background: darken($color-gray, 10);
		th {
			font-weight: 400;
		}
	}
	tbody {
		tr {
			background: $color-gray;
			border-bottom: 2px solid #fff;
		}
	}
	.for-mobile {
		@include for-tablet-under {
			display: table-cell !important;
		}
	}
	td,
	th {
		@include for-tablet-under {
			display: none !important;
		}
	}
}

.reservation-edit {
	.modal-body_content {
		display: flex;
	}
	.form-group {
		margin-bottom: 20px;
	}
	.person-slider {
		margin-bottom: 0;
	}
}
