@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

// .timeslot-picker-wrapper {
// 	margin-top: 50px;
// 	display: block;
// 	.timeslot-picker {
// 		margin-top: 20px;
// 		width: 100%;
// 		max-width: 880px;
// 	}
// }

$cta-height: 120px;
$cta-height-desktop: 80px;

.hide-on-mobile {
	@include for-phone-only {
		display: none !important;
	}
}

.profile-view-cta {
	z-index: 99;
	background: $color-background;
	box-shadow: 0px 3px 66px #5a603e40;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	.container-small {
		// height: $cta-height;
		padding: 20px;
		@include full-center();
		@include for-tablet-up {
			height: $cta-height-desktop;
			flex-direction: row;
			justify-content: space-between;
			.button {
				margin: 0;
				width: max-content;
			}
		}
	}
	.cta-description {
		text-align: center;
	}
	.button {
		margin-top: 20px;
		width: 100%;
	}
}

.profile-view-wrapper {
	.banner-top {
		padding: 200px 0 !important;
	}
}

.profile-view {
	min-height: 400px;
	position: relative;
	z-index: 66;
	border-bottom: 4px solid $color-primary;
	border-radius: 7px 7px 0 0;

	.desktop-category {
		display: flex;
		justify-content: flex-end;
		@include for-phone-only {
			display: none;
		}
		p {
			color: $color-secondary;
		}
	}
	.count {
		background-color: $color-secondary;
		border-bottom: 3px solid rgba(0, 0, 0, 0.4);
		border-radius: 8px;
		width: 46px;
		height: 46px;
		@include full-center();
		@include for-phone-only {
			margin-top: 20px;
		}
		&-disable {
			background: $color-text;
		}
		strong {
			color: #fff;
		}
	}

	.restaurant-logo {
		width: max-content;
		position: absolute;
		top: -120px;
		left: 80px;

		@include for-tablet-under {
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
		@include for-phone-only {
			top: -90px;
		}
		.image {
			width: 220px;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
			height: 220px;
			border-radius: 7px;
			background: #fff;
			object-fit: contain;
			@include for-phone-only {
				width: 180px;
				height: 180px;
			}
		}
		.button-wrapper {
			bottom: -10px;
			left: 0;
			right: 0;
			@include full-center();
			position: absolute;
		}
	}
	.read-more {
		&_visible {
			.read-more-wrapper {
				max-height: 300vh;
				&::after {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		&-wrapper {
			max-height: 180px;
			overflow: hidden;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				width: 100%;
				height: 70px;
				background: #fff;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0) 100%
				);
				opacity: 1;
			}
		}
		&-button {
			color: $color-secondary;
			font-weight: 100;
			cursor: pointer;
		}
	}
	.category-phone {
		display: flex;
		padding-top: 20px !important;
		border-top: 1px solid $color-gray;
		margin-top: 20px;
		width: 100%;
		.styled-text {
			color: $color-secondary;
			align-items: center;
			display: flex;
		}
		.dot {
			@include circle(8px);
			margin-right: 10px;
			display: inline-block;
			background: $color-primary;
		}
	}
	.title-row {
		display: flex;
		.address-distance {
			background: $color-gray;
			color: $color-gray-dark;
			border-radius: 7px;
			padding: 5px;
			margin-left: 15px;
			height: max-content;
			margin-top: 2px;
			@include for-phone-only {
				display: none;
			}
		}
	}
	.restaurant-info {
		display: flex;
		padding-top: 70px;
		@include for-tablet-under {
			flex-direction: column;
		}
		.col {
			padding-top: 40px;
			@include for-tablet-under {
				display: flex;
				flex-wrap: wrap;
			}
		}
		.col-full {
			flex: 1;
			padding: 0 40px;
			margin-bottom: 40px;
			@include for-tablet-under {
				padding: 20px 0 !important;
			}
			h1 {
				padding-bottom: 5px;
				border-bottom: 2px solid $color-secondary;
				margin-bottom: 20px;
			}
		}
		.description {
			color: $color-gray-dark;
			margin-bottom: 20px;
			white-space: pre-wrap;
		}
		.info-item {
			display: flex;
			align-items: center;
			max-width: 340px;
			margin-bottom: 15px;
			padding-right: 20px;
			@include for-phone-only {
				width: 100%;
			}
			.icon {
				width: 50px !important;
			}
			.icon-flip {
				i {
					transform: rotate(90deg);
				}
			}
			p,
			a {
				font-weight: 300;
				color: #434343;
				text-decoration: none;
			}
			i {
				font-size: 24px;
				color: $color-primary;
			}
		}
		.route-button-mobile {
			display: none;
			@include for-phone-only {
				display: flex;
			}
		}
		.route-button-desktop {
			display: flex;
			@include for-phone-only {
				display: none;
			}
		}
		.mobile-cta {
			display: none;
			@include for-phone-only {
				display: flex;
			}
			z-index: 88;
			position: fixed;
			bottom: 0px;
			left: 0;
			right: 0;
			justify-content: center;
			padding-bottom: 24px;
			background: #484b38;
			.button {
				margin-top: calc(-#{$button-height} / 2) !important;
				background: $color-secondary !important;
				margin-right: 20px;
				&:last-child {
					margin: 0;
				}
			}
		}
		.route-button {
			position: absolute;
			left: 0;
			right: 0;
			z-index: 22;
			bottom: calc(-#{$button-height} / 2);
			justify-content: center;
			display: flex;
			@include for-phone-only {
				align-items: center;
			}
			.button {
				margin-right: 20px;
				border: 0;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

.for-desktop {
	display: flex;
	@include for-phone-only {
		display: none !important;
	}
}
.for-phone {
	display: none;
	@include for-phone-only {
		display: flex;
	}
}

.profile-footer {
	@include for-phone-only {
		padding-bottom: 120px;
	}
}
