@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.autocomplete {
	position: absolute;
	@include for-tablet-under {
		position: relative;
		box-shadow: 0px 0px 0px transparent !important;
		border-radius: 0 !important;
	}
	opacity: 0;
	max-height: 0;
	transition: 0.4s ease max-height;
	overflow-y: scroll;
	background: $color-background;
	color: $color-text;
	box-shadow: $shadow;
	z-index: 77;
	margin-top: -8px;
	left: 0;
	right: 0;
	border-radius: 0px 0px 8px 8px;
	&-title {
		font-size: 14px;
		margin: 10px 0;
	}
	&-wrapper {
		padding: 15px;
	}
	&-visible {
		opacity: 1;
		max-height: 500px;
	}
	&-loading {
		@include full-center();
	}

	.suggestion-item {
		padding: 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:nth-child(2n) {
			background-color: lighten($color-gray, 2);
		}
		&-me {
			border: 1px solid $color-gray;
			background: #fff;
		}
		.icon {
			width: 24px;
			text-align: center;
			margin-right: 20px;
			i {
				color: $color-primary;
			}
		}
		strong {
			color: #434343;
			display: block;
			font-size: 12px;
			margin-bottom: 0;
		}
		span {
			font-size: 12px;
			color: #434343;
		}
		&:hover {
			transform: scale(1);
		}
	}
}
