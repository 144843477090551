@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.footer {
	padding: 40px 0;
	&-grid {
		display: grid;
		grid-gap: 15px;
		grid-template-columns: 100px 360px 1fr 1fr 1fr;
		@include for-tablet-under {
			grid-template-columns: repeat(2, 1fr);
			.col:nth-child(3),
			.col:nth-child(4) {
				margin-top: 10px;
				text-align: center;
			}
			.col:nth-child(5) {
				margin-top: 10px;
				text-align: center;
				grid-column: 1 / span 2;
			}
		}
		@include for-phone-only {
			grid-template-columns: repeat(1, 1fr) !important;
			.col {
				grid-column: 1 !important;
			}
		}
	}

	.col {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&-logo {
		@include full-center();
		flex: 1;
		@include for-tablet-under {
			display: none;
		}
		img {
			width: 100px;
		}
	}
	&-socials {
		// @include full-center();
		flex-direction: row;
		display: flex;
		align-items: center;
		padding-left: 50px;
		flex: 1;
		@include for-phone-only {
			padding-left: 0;
			justify-content: center;
		}
	}

	&-list {
		label {
			display: block;
			margin-bottom: 15px;
			font-weight: 500;
			font-size: 16px;
		}
		a {
			display: block;
			color: #000;
			text-decoration: none;
			margin-bottom: 5px;
			font-size: 14px;
			&:last-child {
				margin: 0;
			}
		}
	}
}

.social-button {
	margin-right: 10px;
	&:last-child {
		margin: 0;
	}
	color: $color-primary;
	border: 2px solid $color-primary;
	@include circle(60px);
	@include full-center();
	text-decoration: none;
	i {
		font-size: 28px;
	}
}
