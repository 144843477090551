@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.not-found-view {
	@include full-center();
	flex: 1;
	.styled-text-title {
		margin-bottom: 10px;
	}
	.styled-text-paragraph {
		margin-bottom: 20px;
		max-width: 400px;
		text-align: center;
	}
}
